import React from "react";
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'
import {MdEmail} from 'react-icons/md'

const HeaderSocial = () => {
  return <div className="header_socials header_social_mobile">
    <a href="https://www.linkedin.com/in/nilahomar/" target="_blank" rel="noreferrer"><BsLinkedin/></a>
    <a href="https://github.com/nilahomar" target="_blank" rel="noreferrer"><BsGithub/></a>
    <a href="mailto:nilahomar@gmail.com" target="_blank" rel="noreferrer"><MdEmail/></a>
    </div>
};

export default HeaderSocial;
